@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;600;700;800&display=swap");

/* ===============> Resets */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --gray: #666666;

  --gray-hover: #999999;
  --main-clr: #4a8985;
  --dark: #333333;
}

body {
  min-height: 100vh;

  color: var(--gray) !important;
  letter-spacing: 1px !important;
}

button {
  border: none;
  letter-spacing: inherit;
}

input {
  outline: none;
}

a {
  color: inherit !important;
  text-decoration: none !important;
}

ul {
  list-style: none;
}

img {
  max-width: 100%;
}

h1,
h2,
h3 {
  color: #000;
  font-family: "EB Garamond", serif !important;
}
h1 {
  font-size: 54px !important;
  font-weight: 400 !important;
  padding-bottom: 70px;
  padding-top: 30px;
}
h2 {
  font-size: 28px !important;
  font-weight: 600 !important;
}

h3 {
  font-size: 24px !important;
  font-weight: 400 !important;
}

/* ===============> Nav */
.nav-container {
  transition: all 0.3s linear;
  height: 100px;
  background: transparent;
  position: sticky;
  top: 0;
  z-index: 30;
}

.logo {
  height: 80px;
  transition: all 0.3s linear;
}

.nav-container.scrolled {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  background: white;
  height: 70px;
}
.nav-container.scrolled .logo {
  height: 60px;
}
@media (max-width: 958px) {
  .nav-container {
    position: relative !important;
  }

  .nav-container.scrolled .logo {
    height: 80px;
  }
}

nav {
  padding: 20px 0;
  width: 90%;
  max-width: 1200px;
  min-height: 100px;
  margin: 0 auto;
}
nav li {
  cursor: pointer;
  font-weight: 600 !important;
}

.flag {
  width: 18px;
  height: 12px;
  object-fit: cover;
}

.bars-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid #eee;
  border-radius: 3px;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  background: rgb(247, 247, 247);
}

.bars-icon-container:hover {
  background: #f1f1f1;
  color: #333;
}
.bars-icon-container {
  display: none;
  font-size: 18px;
  background: #f1f1f1;
}

@media (max-width: 958px) {
  .navbar-nav {
    display: none !important;
  }

  .bars-icon-container {
    display: flex;
  }
}
.nav-link {
  padding: 0 15px !important;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 3px !important;
}
.nav-link.inactive {
  color: var(--gray) !important;
}

.nav-link.inactive:hover {
  color: var(--gray-hover) !important;
}

.nav-link.active {
  color: var(--main-clr) !important;
}

.nav-link.active {
  color: var(--main-clr) !important;
}

/* Sidebar */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  visibility: hidden;
  z-index: 50;
  transition: all 0.3s ease 0s;
}

.sidebar {
  display: block;
  position: fixed;
  top: 0;
  right: 0;

  height: 100%;
  width: 300px;
  max-width: 100%;
  z-index: 60;
  padding-bottom: 30px;

  font-size: 1.077em;
  background: #000000;
  transform: translateX(300px);
  color: #fff;
  transition: transform 0.3s ease 0s;
}

.overlay.open {
  background: rgba(0, 0, 0, 0.6);
  visibility: visible;
}

.overlay.open .sidebar {
  transform: translateX(0);
}

.sidebar .nav-link {
  padding: 12px 20px !important;
  display: block;
  color: #707070 !important;
  border-top: 1px solid rgba(255, 255, 255, 0.035);
  border-bottom: 1px solid rgba(255, 255, 255, 0.035);
  border-color: #222222;
  text-transform: capitalize;
}

.sidebar .nav-link:hover {
  color: #fff !important;
}
.sidebar .bars-icon-container {
  padding: 25px 0 25px 20px;
  color: white !important;
  background: transparent;
  border: none;
}

.sidebar .nav-link:last-child::after {
  display: none;
}

.sidebar .spain-flag {
  margin-right: 10px;
}

.sidebar form {
  margin-top: 30px;
  padding: 0 20px;
  position: relative;
}

.top-bar {
  padding: 40px 20px;
  font-size: 4rem;
  background-color: yellow;
}

.back-to-top {
  z-index: 20;
  position: fixed;
  right: 40px;
  bottom: 40px;
  color: var(--main-clr);
  height: 35px;
  line-height: 35px;
  width: 35px;
  font-size: 16px;
  border-radius: 50%;
  transition: all 0.25s ease-in-out;
}

.back-to-top:hover {
  background-color: var(--main-clr);
  color: white;
}

/* ===============> Footer */

footer {
  background-color: #3b3b3a;
  text-align: center;
  color: #fff;
}

footer .content {
  width: 1200px;
  max-width: 90%;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
}

footer img {
  display: block;
  margin: 0 auto;
}

/* ===============> About Page */

main.about {
  overflow-x: hidden !important;
}
main.about .col {
  padding: 0 !important;
}
main.about img {
  max-width: 100%;
}

main.about .text-content {
  padding: 60px 72px 30px 15%;
}

main.about h2 {
  margin-bottom: 20px;
}

main.about .text-content:nth-child(2) {
  padding: 60px 15% 30px 72px;
}

@media (max-width: 768px) {
  main.about .row2 {
    flex-direction: column-reverse !important;
  }

  main.about .text-content {
    padding: 60px 15% 30px 30px;
  }
}

main.about .last-row-container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 130px;
  padding-bottom: 40px;
  position: relative;
  margin-top: 80px;
}

main.about .last-row-container::after {
  position: absolute;
  content: "";
  height: 1px;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #6666661e;
}

main.about .foundress-mobile {
  display: none;
  margin-bottom: 30px;
}
main.about .last-row {
  gap: 60px;
}

main.about .foundress-info {
  flex: 1 1 420px;
}

main.about .last-row .img-container {
  flex-grow: 1;
  max-width: 530px !important;
  flex-shrink: 1;
}

main.about .last-row .img-container img {
  width: 100%;
}
main.about .last-row .foundress-role {
  margin-bottom: 20px;
  font-weight: 600;
  color: var(--gray);
}

@media (max-width: 1122px) {
  main.about .last-row {
    flex-direction: column-reverse;
  }
  main.about .last-row .foundress-desk {
    display: none;
  }
  main.about .foundress-mobile {
    display: block !important;
  }
}

/* ===============> Contact Page */
main.contact {
  width: 1200px;
  max-width: 90%;
  margin: 0 auto;
}
main.contact h3 {
  margin-bottom: 25px;
}
main.contact .flex-item1 {
  flex: 0 1 270px;
}

main.contact .flex-item2 {
  flex: 0 1 550px;
}
@media (max-width: 946px) {
  main.contact .flex-item1 {
    flex: 1 1 270px;
  }
  main.contact .flex-item2 {
    flex: 1 1 550px;
  }
}
main.contact .flex-container {
  gap: 30px;
  margin-bottom: 100px;
}

main.contact .strong {
  font-weight: 600;
  color: black !important;
}

main.contact .strong:hover {
  text-decoration: underline !important;
}

main.contact .flex-item1 .d-flex {
  gap: 28px;
  margin-bottom: 20px;
}

main.contact .flex-item1 .icon {
  font-size: 25px;
  color: black;
}

main.contact .flex-item1 p {
  margin-bottom: 0 !important;
}

main.contact .flex-item1 p span {
  font-weight: 600;
}

main.contact .flex-item2 .star {
  color: red;
  margin-left: 4px;
}

main.contact .flex-item2 label {
  font-size: 15px;
  font-weight: 400 !important;
  margin-bottom: 18px;
}

main.contact .flex-input-container {
  gap: 20px;
}
main.contact .flex-input-container .input-container {
  flex-grow: 1;
}

main.contact .flex-item2 button {
  background: var(--gray);
  transition: all 0.15s ease-in-out;
  border: none;
}

main.contact .flex-item2 button:hover {
  background: #3b86b0;
}

/* ===============> Services */
main.services {
  overflow-x: hidden !important;
}

main.services .col {
  padding: 0 !important;
}
main.services img {
  max-width: 100%;
}

main.services .text-content {
  padding: 60px 72px 30px 15%;
}

main.services h2 {
  margin-bottom: 20px;
}

main.services .text-content:nth-child(2) {
  padding: 60px 15% 30px 72px;
}

@media (max-width: 767px) {
  main.services .row1,
  main.services .row3 {
    flex-direction: column-reverse;
  }
  main.services .text-content {
    padding: 60px 15% 30px 30px;
  }
}

main.services .last-row-container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 130px;
  padding-bottom: 40px;
  position: relative;
  margin-top: 80px;
}

main.services .last-row-container::after {
  position: absolute;
  content: "";
  height: 1px;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #6666661e;
}

main.services .foundress-mobile {
  display: none;
  margin-bottom: 30px;
}
main.services .last-row {
  gap: 60px;
}

main.services .foundress-info {
  flex: 1 1 420px;
}

main.services .last-row .img-container {
  flex-grow: 1;
  max-width: 530px !important;
  flex-shrink: 1;
}

main.services .last-row .img-container img {
  width: 100%;
}
main.services .last-row .foundress-role {
  margin-bottom: 20px;
  font-weight: 600;
  color: var(--gray);
}

@media (max-width: 1122px) {
  main.services .last-row {
    flex-direction: column-reverse;
  }
  main.services .last-row .foundress-desk {
    display: none;
  }
  main.services .foundress-mobile {
    display: block !important;
  }
}
main.services ul {
  list-style: disc;
}

/* ===============> Faqs Page */
main.faqs {
  width: 1200px;
  max-width: 90%;
  margin: 0 auto;
}
main.faqs .d-flex {
  gap: 30px;
}
main.faqs .faqs-container {
  flex: 1 1 480px;
}

main.faqs .faqs-container .accordion-item {
  margin: 30px 0;
  font-family: inherit !important;
}

main.faqs .accordion-header .accordion-button {
  background-color: #f7f7f7 !important;
  color: black;
  font-size: 20px;
}

main.faqs .accordion-item h2 {
  font-family: Segoe UI, sans-serif !important;
}
main.faqs ul {
  list-style: disc !important;
}

main.faqs h4 {
  color: #000000;
}

main.faqs .cover-container {
  width: 100%;
  height: 360px;
  margin: 80px 0 50px;
}

main.faqs .cover-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* ===============> Home Page */
.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: black;
}
.carousel-indicators {
  display: none !important;
}

.slider-container {
  margin-top: -100px;
  min-height: 100vh;
  z-index: -1;
}
.slider-container {
  width: 100%;
}

.slider-container h1 {
  color: white;
  font-size: 54px;
  font-weight: 700 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.slider-container .light-blue-bg {
  font-size: 25px;
  padding: 2px 7px;
  font-weight: 600;
  color: white;

  background-color: var(--main-clr);
  display: inline-block;
}

.slider-item-el {
  background-size: cover !important;
  background-position: center !important;
}
.slider-item-el .content-container {
  min-height: 100vh;
  width: 90vw;
  max-width: 1200px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
}
.slider-item-el.item1 .content-container,
.slider-item-el.item2 .content-container {
  justify-content: flex-end;
  padding: 150px 0;
}

.slider-item-el.item3 .content-container {
  align-items: flex-end;
  padding: 101px 0;
}

.slider-item-el.item4 .content-container {
  justify-content: center;
  align-items: center;
}

.slider-item-el.item1 {
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.247),
      rgba(0, 0, 0, 0.247)
    ),
    url("./images/home-imgs/img-home-slider-1.png");
}
.slider-item-el.item2 {
  background: url("./images/home-imgs/img-home-slider-2.png");
}
.slider-item-el.item3 {
  background: url("./images/home-imgs/img-home-slider-3.png");
}
.slider-item-el.item4 {
  background: url("./images/home-imgs/img-home-slider-4.png");
}
.slider-item-el img {
  max-height: 100%;
}

.slider-item-el .big-text {
  font-size: 34px;
  font-weight: 700 !important;
  font-family: "EB Garamond", serif !important;
  color: white !important;
}

.slider-item-el.item4 .big-text {
  font-size: 44px !important;
}
.slider-item-el.item2 .text-container h1 {
  margin: 0;
}
.slider-item-el.item3 .text-container .big-text-dark {
  max-width: 400px;
  margin: 0 auto;
  font-size: 44px !important;
}

.slider-item-el.item4 h1 {
  font-size: 62px !important;
  color: var(--main-clr);
}
.slider-container .big-text-dark {
  color: var(--dark) !important;
}
@media (max-width: 768px) {
  .slider-container,
  .slider-item-el {
    width: 100%;
    min-height: 40vh !important;
  }
  .slider-container h1 {
    font-size: 30px;
  }
  .slider-container .light-blue-bg {
    font-size: 18px;
  }
  .slider-item-el .big-text {
    font-size: 26px !important;
  }
  .slider-item-el.item3 .text-container .big-text-dark {
    font-size: 35px !important;
  }
  .slider-item-el.item4 h1 {
    font-size: 40px !important;
  }
  .slider-item-el.item3 .content-container {
    justify-content: center;
  }
  .slider-item-el.item4 h1 {
    font-size: 50px !important;
  }
}

section.about-section {
  width: 1200px;
  max-width: 90%;
  margin: 120px auto 0;
  padding: 0 40px;
}
section.about-section .d-flex {
  gap: 30px;
}
section.about-section .flex-item {
  flex: 0 1 257px;
}

section.about-section .flex-item img {
  margin-bottom: 20px;
}
section.about-section .flex-item p {
  font-size: 14px;
}
section.about-section h3 {
  font-size: 22px !important;
  font-weight: 600 !important;
  margin-bottom: 10px;
}

section.about-section button {
  margin-top: 50px;
  background-color: var(--main-clr);

  color: white;
  border-radius: 50px;
  font-size: 20.7px;
  transition: all 0.25s ease-in-out;
  padding: 10px 20px;
}

section.about-section button:hover {
  color: white;
  background-color: var(--gray);
}

section.services-section {
  background: var(--main-clr) url("./images/home-imgs/patern-bg.png");
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 50px;
  padding: 40px 0;
  color: white;
}
section.services-section h2 {
  color: white !important;
}
section.services-section .container {
  width: 1200px;
  max-width: 90%;
  margin: 0 auto;
}
section.services-section p {
  font-size: 20px;
  margin-bottom: 40px;
}

section.services-section .d-flex {
  gap: 20px;
  margin-bottom: 40px;
}
section.services-section .flex-item {
  flex: 0 1 322px;
}

section.services-section .flex-item h3 {
  margin-top: 20px;
  font-weight: 600 !important;
  color: #222;
}

section.services-section button {
  margin-top: 50px;

  background-color: var(--dark);
  color: white;
  border-radius: 50px;
  font-size: 20.7px;
  transition: all 0.25s ease-in-out;
  padding: 10px 20px;
  margin-top: 20px;
}

section.services-section button:hover {
  color: white;
  background-color: var(--main-clr);
}

section .h2 {
  margin-bottom: 20px;
  font-size: 40px !important;
  font-weight: 400 !important;
}

section.faqs-section {
  padding: 20px 0 60px;
  background-image: url("./images/home-imgs/faqs-section-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  position: relative;
  color: black;
}
section.faqs-section .h2 {
  color: black !important;
}
section.faqs-section .big-text {
  font-size: 24px;
  font-weight: 400;
  margin: 15px 0;
}

section.faqs-section button {
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  color: rgb(59, 59, 58);
  border-radius: 50px;
  padding: 8px 16px;
  letter-spacing: inherit;
  transition: all 0.25s ease-in-out;
}
section.faqs-section button:hover {
  color: var(--main-clr);
}

/* section.faqs-section::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: url("./images/home-imgs/faqs-section-bg2.png");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
} */

section.faqs-section .container {
  width: 1200px;
  max-width: 90%;
  margin: 0 auto;
}

section.contact-section {
  width: 1200px;
  max-width: 90%;
  margin: 100px auto 0;
}
section.contact-section h3 {
  margin-bottom: 25px;
}
section.contact-section .flex-item1 {
  flex-basis: 270px;
  flex-shrink: 1;
}
section.contact-section .flex-item1 button {
  transition: all 0.25s ease-in-out;
  color: white;
  background-color: var(--gray);
  padding: 7px 14px;
  font-size: 14px;
  border-radius: 3px;
  margin-top: 20px;
}

section.contact-section .flex-item1 button:hover {
  background-color: var(--dark);
}

section.contact-section .flex-item2 {
  flex: 0 1 710px;
  height: 400px;
}

section.contact-section iframe {
  width: 100%;
  height: 100%;
}

section.contact-section .flex-container {
  gap: 30px;
  margin-bottom: 100px;
}

section.contact-section .strong {
  font-weight: 600;
  color: black !important;
}
section.contact-section .flex-item1 p span {
  font-weight: 600;
}

section.contact-section .strong:hover {
  text-decoration: underline !important;
}

section.contact-section .flex-item1 .d-flex {
  gap: 28px;
  margin-bottom: 20px;
}

section.contact-section .flex-item1 .icon {
  font-size: 25px;
  color: black;
}

section.contact-section .flex-item1 p {
  margin-bottom: 0 !important;
}
